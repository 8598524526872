import { yupResolver } from '@hookform/resolvers/yup';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router';
import * as yup from 'yup';
import { TransparentButton } from '../../../../../aida/components/TransparentButton';
import { FormFieldType } from '../../../../../extViewmodels';
import {
	useClientCreateEventRegistrationResponseMutation,
	useClientGetEventRegistrationResponseByToken,
	useClientUpdateEventRegistrationResponseMutation,
} from '../../../../../queries';
import { Button } from '../../../../../web/components/Button';
import { Checkbox } from '../../../../../web/components/Checkbox';
import { LoadingSpinner } from '../../../../../web/components/LoadingSpinner';
import { RadioButton } from '../../../../../web/components/RadioButton';
import { ISelectBoxOption, SelectBox } from '../../../../../web/components/SelectBox';
import { TextInput } from '../../../../../web/components/TextInput';
import { TrashIcon } from '../../../../../web/components/svgs/icons/TrashIcon';
import { baseStyleSheet } from '../../../../../web/styles/styles';
import { IEventRecipient, IEventRegistrationSurvey, IEventSurveyResponse } from '../../../../models/Api';
import { ToastMessageBuilder, useToasterContext } from '../../../ToasterProvider/context';
import { getEventRecipientFirstAndLastName } from '../utils';
import { styleSheet } from './styles';
import {
	areGuestNamesValid,
	buildCustomPropertiesSchema,
	getRsvpBackground,
	handleMutationError,
	handleMutationSuccess,
} from './utils';

type Props = {
	survey: IEventRegistrationSurvey;
	onResponseSubmitted?(response: IEventSurveyResponse): void;
	surveyResponse: IEventSurveyResponse;
	setSurveyResponse: React.Dispatch<React.SetStateAction<IEventSurveyResponse>>;
};

const usPhoneRegExp = new RegExp(/^((\+1)?[\s-]?)?\(?[1-9]\d\d\)?[\s-]?[1-9]\d\d[\s-]?\d\d\d\d$/);

interface IFormValues {
	isAttending: boolean | null;
	bringingGuest?: boolean;
	email?: string;
	firstName?: string;
	lastName?: string;
	phone?: string;
	guests: IEventRecipient[];
	customProperties: Record<string, string>;
}

const DEFAULT_VALUES: IFormValues = {
	isAttending: null,
	bringingGuest: false,
	email: '',
	guests: [{ firstName: '', lastName: '' }],
	firstName: '',
	lastName: '',
	phone: '',
	customProperties: {},
};
export const ErrorMessage = ({ message }: { message: string }) => (
	<p className={`${css(styleSheet.errorMessage)} ${css(styleSheet.noMargin)}`}>{message}</p>
);

export const EventSurveyForm = ({ survey, onResponseSubmitted, setSurveyResponse, surveyResponse }: Props) => {
	const { params: urlParams = {} } = useRouteMatch<{
		token?: string;
	}>();
	const { data: recordedResponse } = useClientGetEventRegistrationResponseByToken({
		accessToken: urlParams.token,
		retry: false,
	});
	const [loading, setLoading] = React.useState<boolean>(true);

	const { toast } = useToasterContext();

	const validationSchema = React.useMemo(() => {
		return yup.object().shape({
			isAttending: yup.boolean().required('Response is required'),
			email: yup.string().trim().email('Invalid email').required('Email is required'),
			guests: survey?.attendeeOptions?.guestLimit
				? yup
						.array()
						.of(
							yup
								.object()
								.shape({
									firstName: yup.string(),
									lastName: yup.string(),
								})
								.test(
									'if-one-entered-both-first-and-last-must-be-defined',
									"If providing a guest's name, both their first and last name must be entered.",
									({ firstName, lastName }) => areGuestNamesValid({ firstName, lastName })
								)
						)
						.max(survey.attendeeOptions.guestLimit)
				: undefined,
			firstName: yup.string().required('First name is required'),
			lastName: yup.string().required('Last name is required'),
			phone: survey?.attendeeOptions?.requirePhoneNumber
				? yup.string().trim().matches(usPhoneRegExp, 'Must be a valid phone number').required('Phone is required')
				: yup
						.string()
						.optional()
						.trim()
						.matches(usPhoneRegExp, { excludeEmptyString: true, message: 'Must be a valid phone number' }),
			customProperties: yup.mixed().when('isAttending', {
				is: true,
				then: () =>
					survey?.customForm?.fields
						? buildCustomPropertiesSchema(
								survey.customForm.fields.map(field => ({
									...field,
									id: field.id || '', // Ensure 'id' is defined, provide a default value if necessary
								}))
							)
						: yup.object().shape({}),
				otherwise: () => yup.object().shape({}),
			}),
		});
	}, [survey]);

	const { handleSubmit, control, formState, getValues, watch, setValue, register } = useForm({
		defaultValues: DEFAULT_VALUES,
		resolver: yupResolver(validationSchema),
	});

	const { fields, append, remove } = useFieldArray<IFormValues>({
		control,
		name: 'guests',
	});

	const handleAttendingYes = React.useCallback(() => {
		setValue('isAttending', true);
	}, [setValue]);

	const handleAttendingNo = React.useCallback(() => {
		setValue('isAttending', false);
		setValue('guests', DEFAULT_VALUES.guests);
	}, [setValue]);

	const createResponseMutation = useClientCreateEventRegistrationResponseMutation({
		onError: e => handleMutationError(toast, e),
		onSuccess: data =>
			handleMutationSuccess(toast, setSurveyResponse, onResponseSubmitted, 'RSVP successfully submitted', data),
	});
	const updateResponseMutation = useClientUpdateEventRegistrationResponseMutation({
		onError: e => handleMutationError(toast, e),
		onSuccess: data =>
			handleMutationSuccess(toast, setSurveyResponse, onResponseSubmitted, 'RSVP successfully updated', data),
	});

	const isPastEvent = React.useMemo(() => {
		const dueDateString = survey?.attendeeOptions?.registrationDeadline || survey?.eventInformation?.startTime;
		return dueDateString ? moment(dueDateString).isBefore(new Date()) : false;
	}, [survey]);

	React.useEffect(() => {
		setLoading(true);
		if (!urlParams.token || urlParams.token.toLocaleLowerCase() === 'unavailable') {
			setLoading(false);
			return;
		}

		const getValueFromRegisteredAndUnregisteredGuests = () => {
			const guestsRegisteredByName = recordedResponse?.guests ?? [];
			const anonymousGuestCount = recordedResponse?.anonymousGuestCount ?? 0;
			if (guestsRegisteredByName.length || recordedResponse?.anonymousGuestCount) {
				const registeredGuests = [...guestsRegisteredByName];
				for (let i = 0; i < anonymousGuestCount; i++) {
					registeredGuests.push({ firstName: '', lastName: '' });
				}
				return registeredGuests;
			}
			return DEFAULT_VALUES.guests;
		};

		if (recordedResponse) {
			const { firstName, lastName } = getEventRecipientFirstAndLastName(recordedResponse.attendee);
			setSurveyResponse(recordedResponse);
			setValue('isAttending', recordedResponse?.isAttending);
			setValue('email', recordedResponse?.attendee?.email);
			setValue('guests', getValueFromRegisteredAndUnregisteredGuests());
			setValue('firstName', firstName);
			setValue('lastName', lastName);
			setValue('phone', recordedResponse?.attendee?.phone);
			setValue('bringingGuest', Boolean(recordedResponse?.guests?.length || recordedResponse?.anonymousGuestCount));

			const customProperties = recordedResponse?.customProperties ?? {};
			if (customProperties) {
				Object.entries(customProperties).forEach(([key, value]) => {
					setValue(`customProperties.${key}`, value);
				});
			}
			setLoading(false);
			return;
		}
		/**
		 * No data found for a user from the token;
		 * continue as an anonymous user
		 */
		setLoading(false);
	}, [recordedResponse, setValue, urlParams.token, setSurveyResponse]);

	const stats = survey?.stats?.responseStats?.find(x => x.category === 'Total');

	const isEventFull =
		stats && survey?.attendeeOptions?.maximumCapacity
			? stats.attending >= survey.attendeeOptions.maximumCapacity
			: false;

	const formValues = watch();

	const rsvpBackground = React.useMemo(
		() => getRsvpBackground(formValues?.isAttending, isPastEvent, isEventFull),
		[formValues?.isAttending, isPastEvent, isEventFull]
	);

	const handleSave = () => {
		const values = getValues();

		const body: IEventSurveyResponse = {
			anonymousGuestCount: 0,
			attendee: {
				email: values.email.trim(),
				firstName: values.firstName.trim(),
				lastName: values.lastName.trim(),
				phone: values.phone?.trim(),
			},
			guests: [],
			isAttending: values.isAttending,
		};

		if (values.bringingGuest) {
			const guests = values.guests.filter(guest => guest.firstName?.trim() && guest.lastName?.trim());
			body.guests = guests;
			body.anonymousGuestCount = values.guests.length - guests.length;
		}

		if (Object.values(values.customProperties).length) {
			const fieldsWithMissingInput = survey.customForm?.fields?.filter(
				x => !x.isOptional && !x.isHidden && !values.customProperties[x.id]
			);
			if (fieldsWithMissingInput?.length && body.isAttending) {
				toast(ToastMessageBuilder.error().setMessage(`Please fill out all required fields`).build());
				return;
			}
			body.customProperties = values.customProperties;
		}

		// PUT/Update if we have an id
		if (surveyResponse?.id) {
			const payload = {
				...surveyResponse,
				...body,
			};
			updateResponseMutation.mutate({
				accessToken: urlParams.token,
				body: payload,
			});
			return;
		}

		// POST/Create
		createResponseMutation.mutate({ body, token: urlParams.token });
	};

	if (loading || !survey) {
		return <LoadingSpinner type='small' />;
	}

	let surveyWarningText = '';
	if (isPastEvent) {
		surveyWarningText = 'Sorry, the event registration deadline has passed.';
	}
	if (isEventFull) {
		surveyWarningText = 'Sorry, the event has reached the maximum capacity of attendees.';
	}
	surveyWarningText = `${surveyWarningText} Please reach out to the event organizer for details.`;
	return (
		<form className={css(styleSheet.rsvpForm)} onSubmit={handleSubmit(handleSave)}>
			{!isPastEvent && !isEventFull && (
				<div className={css(styleSheet.rsvpInfo)}>
					<div className={css(styleSheet.formControl)}>
						<label htmlFor='client-firstName' className={css(styleSheet.label)}>
							First Name
						</label>
						<Controller
							name='firstName'
							control={control}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='client-firstName' name='firstName' type='text' {...fieldProps} />
							)}
						/>
						{formState.isSubmitted && formState.errors.firstName && (
							<ErrorMessage message={formState.errors.firstName.message} />
						)}
					</div>

					<div className={css(styleSheet.formControl)}>
						<label htmlFor='client-lastName' className={css(styleSheet.label)}>
							Last Name
						</label>
						<Controller
							name='lastName'
							control={control}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='client-lastName' name='lastName' type='text' {...fieldProps} />
							)}
						/>
						{formState.isSubmitted && formState.errors.lastName && (
							<ErrorMessage message={formState.errors.lastName.message} />
						)}
					</div>

					<div className={css(styleSheet.formControl)}>
						<label htmlFor='client-email' className={css(styleSheet.label)}>
							Email
						</label>
						<Controller
							name='email'
							control={control}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='client-email' type='text' {...fieldProps} />
							)}
						/>
						{formState.isSubmitted && formState.errors.email && (
							<ErrorMessage message={formState.errors.email.message} />
						)}
					</div>

					<div className={css(styleSheet.formControl)}>
						<label htmlFor='client-phone' className={css(styleSheet.label)}>
							Phone{' '}
							{!survey?.attendeeOptions?.requirePhoneNumber ? <small style={{ fontSize: 11 }}>(Optional)</small> : null}
						</label>
						<Controller
							name='phone'
							control={control}
							render={({ field: { ref, ...fieldProps } }) => (
								<TextInput inputId='client-phone' type='text' {...fieldProps} placeholder='(999)999-9999' />
							)}
						/>
						{formState.isSubmitted && formState.errors.phone && (
							<ErrorMessage message={formState.errors.phone.message} />
						)}
					</div>
				</div>
			)}
			<div
				className={css(styleSheet.rsvpOptions)}
				style={{
					background: rsvpBackground,
				}}
			>
				{!isPastEvent && !isEventFull ? (
					<Controller
						name='isAttending'
						control={control}
						render={({ field: { ref, ...fieldProps } }) => (
							<>
								<RadioButton
									checked={fieldProps.value === true}
									className={css(styleSheet.radio)}
									id='attending-yes'
									name='attending'
									onChange={handleAttendingYes}
								>
									Yes, I plan on attending
								</RadioButton>

								<RadioButton
									checked={fieldProps.value === false}
									className={css(styleSheet.radio)}
									id='attending-no'
									name='attending'
									onChange={handleAttendingNo}
								>
									No, won&apos;t be able to make it
								</RadioButton>
							</>
						)}
					/>
				) : (
					<p className={css(styleSheet.noMargin)}>{surveyWarningText}</p>
				)}
			</div>
			{formState.isSubmitted && formState.errors.isAttending && (
				<ErrorMessage message={formState.errors.isAttending.message} />
			)}

			{!isPastEvent && !isEventFull && survey?.attendeeOptions?.guestLimit && formValues.isAttending ? (
				<div className={css(styleSheet.plusOneOptionWrap)}>
					<div className={css(styleSheet.formControl)}>
						<Controller
							name='bringingGuest'
							control={control}
							render={({ field: { ref, ...fieldProps } }) => (
								<Checkbox
									checked={fieldProps.value}
									className={css(styleSheet.checkbox)}
									id='bringingGuest'
									name='bringingGuest'
									onChange={() =>
										fieldProps.onChange(() => {
											const toggle = !fieldProps.value;
											if (!toggle) {
												setValue('guests', DEFAULT_VALUES.guests);
											}
											setValue('bringingGuest', toggle);
										})
									}
								>
									<div className={css(styleSheet.bringingGuestsLabel)}>I&apos;ll be bringing a guest</div>
								</Checkbox>
							)}
						/>
					</div>
					{formValues.bringingGuest ? (
						<>
							{fields.map((item, index) => (
								<React.Fragment key={item.id}>
									<div className={css(styleSheet.guestInfo)}>
										<div className={css(styleSheet.formControl)}>
											<label htmlFor={`client-guest-${item.id}-first-name`} className={css(styleSheet.label)}>
												Guest First Name:
											</label>
											<input
												id={`client-guest-${item.id}-first-name`}
												key={item.id}
												className={`text-input ${css(styleSheet.textInput)}`}
												{...register(`guests.${index}.firstName`)}
												type='text'
												placeholder={formValues.guests[index].lastName ? '' : 'Optional'}
											/>
										</div>
										<div className={css(styleSheet.formControl)}>
											<label htmlFor={`client-guest-${item.id}-last-name`} className={css(styleSheet.label)}>
												Guest Last Name:
											</label>

											<input
												{...register(`guests.${index}.lastName`)}
												type='text'
												id={`client-guest-${item.id}-last-name`}
												key={item.id}
												className={`text-input ${css(styleSheet.textInput)}`}
												placeholder={formValues.guests[index].firstName ? '' : 'Optional'}
											/>
										</div>
										<TransparentButton onClick={() => remove(index)}>
											<TrashIcon className={css(styleSheet.removeGuestIcon)} />
										</TransparentButton>
									</div>
									{formState.isSubmitted && formState.errors.guests?.[index] && (
										<div className={css(styleSheet.guestInfoError)}>
											<ErrorMessage message={formState.errors.guests[index].message} />
										</div>
									)}
								</React.Fragment>
							))}
							{survey.attendeeOptions.guestLimit > fields.length ? (
								<button
									type='button'
									className={css(styleSheet.addAnotherGuestButton)}
									onClick={() => append({ firstName: '', lastName: '' })}
								>
									+ Add another guest
								</button>
							) : null}
						</>
					) : null}
				</div>
			) : null}
			{!survey?.customFormIsDisabled && formValues.isAttending ? (
				<>
					{survey?.customForm?.fields
						?.filter(f => !f.isHidden)
						.map(f => {
							const fieldDropdownOptions = f.options?.map(o => {
								return {
									id: o.id,
									title: o.label,
									value: o.label,
								};
							});

							return (
								<div key={f.id} className={css(styleSheet.formControlFullWidth)}>
									<label htmlFor={f.id} className={css(styleSheet.label)}>
										{f.label}
										{!f.isOptional ? (
											<>
												{' '}
												<span className={css(baseStyleSheet.required)}>*</span>
											</>
										) : null}
									</label>
									<Controller
										name={`customProperties.${f.id}`}
										control={control}
										render={({ field: { ref, ...fieldProps } }) => {
											if (f.fieldType === FormFieldType.String) {
												return (
													<>
														<TextInput
															type='text'
															inputId={f.id}
															inputClassName={css(styleSheet.textInput)}
															value={fieldProps.value || ''}
															{...fieldProps}
														/>
														{formState?.errors.customProperties?.[f.id] && (
															<ErrorMessage message={formState?.errors.customProperties[f.id]?.message} />
														)}
													</>
												);
											}

											if (f.fieldType === FormFieldType.Option) {
												const onSelectionChanged = (selectedOption: ISelectBoxOption<string>) => {
													setValue(`customProperties.${f.id}`, selectedOption.id);
												};
												const onRenderTriggerTitle = (selectedOption: ISelectBoxOption<string>) => {
													return <span>{selectedOption.title}</span>;
												};
												return (
													<>
														<SelectBox
															options={fieldDropdownOptions}
															onRenderSelectedOptionTitle={onRenderTriggerTitle}
															onSelectionChanged={onSelectionChanged}
															placeholder={<span>Select option</span>}
															triggerClassName={css(styleSheet.selectBoxtriggerButton)}
															{...fieldProps}
															selectedOption={fieldDropdownOptions?.find(x => x.id === fieldProps.value) || undefined}
														/>
														{formState?.errors.customProperties?.[f.id] && (
															<ErrorMessage message={formState?.errors.customProperties[f.id]?.message} />
														)}
													</>
												);
											}

											if (f.fieldType === FormFieldType.MultipleOptions) {
												const fieldCheckboxOptions = f.options?.map(o => {
													const fieldCheckboxSelectedOptions: string[] = fieldProps?.value?.split(',') ?? [];
													/**
													 * Method to update the value of the field
													 * @param e {React.ChangeEvent<HTMLInputElement>}
													 * sets a {String} value of the field
													 */
													const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
														const newOptions = [...fieldCheckboxSelectedOptions];
														if (e.target.checked) {
															newOptions.push(o.id);
														} else {
															newOptions.splice(fieldCheckboxSelectedOptions.indexOf(o.id), 1);
														}

														setValue(`customProperties.${f.id}`, `${newOptions.join(',')}`);
													};
													/**
													 * Use the onChange method to update the value of the field
													 */
													fieldProps.onChange = onChange;
													return (
														<Checkbox
															key={o.id}
															id={o.id}
															className={css(styleSheet.checkbox)}
															checked={fieldCheckboxSelectedOptions?.includes(o.id)}
															{...fieldProps}
														>
															<span>{o.label}</span>
														</Checkbox>
													);
												});
												return (
													<>
														{fieldCheckboxOptions}
														{formState?.errors.customProperties?.[f.id] && (
															<ErrorMessage message={formState?.errors.customProperties[f.id]?.message} />
														)}
													</>
												);
											}

											return null;
										}}
									/>
								</div>
							);
						})}
				</>
			) : null}
			<footer className={css(styleSheet.actions)}>
				{!isPastEvent && !isEventFull ? (
					<>
						<div className={css(styleSheet.divider)} />
						<div className={css(styleSheet.buttonContainer)}>
							<Button
								type='submit'
								kind='primary'
								disabled={createResponseMutation.isLoading || updateResponseMutation.isLoading}
								isLoading={createResponseMutation.isLoading || updateResponseMutation.isLoading}
								label={surveyResponse?.id ? 'Update Response' : 'RSVP'}
							/>
						</div>
					</>
				) : null}
			</footer>
		</form>
	);
};
