import * as yup from 'yup';
import { mention, pink2, successTint } from '../../../../../web/styles/colors';
import { IToastMessage, ToastMessageBuilder } from '../../../ToasterProvider/context';

export const areGuestNamesValid = ({
	firstName,
	lastName,
}: {
	firstName?: string;
	lastName?: string;
}): boolean => {
	// If both are empty, it's valid
	if (!firstName && !lastName) return true;
	// Only one provided: invalid
	if (!firstName || !lastName) return false;
	// Both provided: check that trimmed values exist
	return !!firstName.trim() === !!lastName.trim();
};

export const buildCustomPropertiesSchema = (
	fields: { id: string; isHidden?: boolean; isOptional?: boolean; label: string }[]
) => {
	return yup.object().shape(
		fields.reduce(
			(schema, field) => {
				if (field.isHidden) return schema;
				const errorMessage = `${field.label} is required`;
				schema[field.id] = field.isOptional ? yup.string().optional() : yup.string().required(errorMessage);
				return schema;
			},
			{} as Record<string, any>
		)
	);
};

export const handleMutationError = (toast: (toastMessage: IToastMessage) => void, error: any) => {
	toast(ToastMessageBuilder.error().setMessage(error?.systemMessage).build());
};

export const handleMutationSuccess = (
	toast: (toastMessage: IToastMessage) => void,
	setSurveyResponse: React.Dispatch<any>,
	onResponseSubmitted: (data: any) => void,
	successMessage: string,
	data: any
) => {
	setSurveyResponse(data);
	toast(ToastMessageBuilder.success().setMessage(successMessage).build());
	onResponseSubmitted?.(data);
};

export const getRsvpBackground = (attending: boolean | null, IS_PAST_EVENT: boolean, EVENT_FULL: boolean): string => {
	if (attending && !IS_PAST_EVENT && !EVENT_FULL) return successTint;
	if (attending === false || IS_PAST_EVENT || EVENT_FULL) return pink2;
	return mention;
};
