import * as React from 'react';

export type ToastMessageDuraton = 'short' | 'long';
export enum ToastMessageType {
	Custom,
	Error,
	Success,
	Text,
}

export interface IToastMessage {
	customContent?: React.ReactNode;
	duration?: ToastMessageDuraton | number;
	message?: string;
	onHide?(): void;
	onShow?(): void;
	type?: ToastMessageType;
}

export interface IToasterContext {
	currentToastMessage?: IToastMessage;
	toast(toastMessage: IToastMessage): void;
}

export class ToastMessageBuilder {
	protected message: IToastMessage = {
		duration: 'long',
		type: ToastMessageType.Text,
	};

	public static success = () => {
		return new ToastMessageBuilder().setType(ToastMessageType.Success);
	};

	public static error = () => {
		return new ToastMessageBuilder().setType(ToastMessageType.Error);
	};

	public static custom = () => {
		return new ToastMessageBuilder().setType(ToastMessageType.Custom);
	};

	public static text = () => {
		return new ToastMessageBuilder().setType(ToastMessageType.Text);
	};

	public setType = (type: ToastMessageType) => {
		this.message.type = type;
		return this;
	};

	public setDuration = (duration: ToastMessageDuraton | number) => {
		this.message.duration = duration;
		return this;
	};

	/** Implicitly sets type to ToastMessageType.Custom */
	public setCustomContent = (content: React.ReactNode) => {
		this.message.customContent = content;
		this.message.type = ToastMessageType.Custom;
		return this;
	};

	public setMessage = (message: string) => {
		this.message.message = message;
		return this;
	};

	public setOnShow = (onShow: () => void) => {
		this.message.onShow = onShow;
		return this;
	};

	public setOnHide = (onHide: () => void) => {
		this.message.onHide = onHide;
		return this;
	};

	public build = () => {
		return this.message;
	};
}

export const ToasterContext = React.createContext<IToasterContext>(null);

export const useToasterContext = () => {
	const context = React.useContext(ToasterContext);
	if (!context) {
		throw new Error('This component requires the use of "ToasterContext".');
	}

	return context;
};
