import { IEventRecipient } from '../../../models/Api';

export const getEventRecipientFirstAndLastName = (eventRecipient: IEventRecipient) => {
	const emptyResponse = { firstName: '', lastName: '' };
	if (!eventRecipient) {
		return emptyResponse;
	}
	if (eventRecipient.firstName && eventRecipient.lastName) {
		return { firstName: eventRecipient.firstName, lastName: eventRecipient.lastName };
	}
	if (!eventRecipient.name) {
		return emptyResponse;
	}
	const nameParts = eventRecipient.name.split(' ');
	if (nameParts.length === 1) {
		return { firstName: nameParts[0], lastName: '' };
	}
	if (nameParts.length === 2) {
		return { firstName: nameParts[0], lastName: nameParts[1] };
	}
	if (nameParts.length > 2) {
		const firstName = nameParts.slice(0, nameParts.length - 1).join(' ');
		const lastName = nameParts[nameParts.length - 1];
		return { firstName, lastName };
	}
};
