import { StyleSheet } from 'aphrodite';
import { hexToRGBA } from '../../../../../models/UiUtils';
import {
	borderColor,
	charmGray,
	header,
	label,
	nameCircles,
	spanishBlue,
	titles,
} from '../../../../../web/styles/colors';
import { CssSelectors } from '../../../../../web/styles/styles';

export const styleSheet = StyleSheet.create({
	rsvpForm: {
		maxWidth: 1024,
		width: '100%',
	},
	rsvpInfo: {
		'@media only screen and (min-width: 1024px)': {
			flexDirection: 'row',
			justifyContent: 'space-between',
			margin: '24px auto 0',
			width: '100%',
			gap: 24,
			...CssSelectors.allChildren(
				{
					width: '100%',
				},
				'div'
			),
		},

		display: 'flex',
		flexDirection: 'column',
		marginTop: 12,
		paddingTop: 12,
	},
	formControl: {
		'@media only screen and (min-width: 1024px)': {
			maxWidth: '33%',
		},
		margin: '0 0 16px',
		textAlign: 'left',
		width: '100%',
		...CssSelectors.allChildren(
			{
				color: header,
				fontSize: 12,
			},
			'small'
		),
	},
	formControlFullWidth: {
		margin: '0 0 16px',
		textAlign: 'left',
		width: '100%',
	},
	label: {
		color: label,
		display: 'block',
		fontSize: 14,
		margin: '0 0 8px',
		textAlign: 'left',
	},
	addAnotherGuestButton: {
		color: spanishBlue,
		marginBottom: 6,
	},
	removeGuestIcon: {
		verticalAlign: 'middle',
	},
	noMargin: {
		margin: 0,
	},
	errorMessage: {
		color: 'red',
	},
	guestInfoError: {
		display: 'flex',
		justifyContent: 'flex-start',
		marginBottom: 6,
	},
	rsvpOptions: {
		'@media only screen and (min-width: 768px)': {
			flexDirection: 'row',
			margin: '24px auto 24px',
			maxWidth: 1024,
			padding: 24,
		},
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginLeft: 0,
		marginRight: 0,
		marginTop: 24,
		marginBottom: 24,
		padding: 12,
	},
	radio: {
		':not(:last-child)': {
			margin: '0 0 12px 0',
		},
		'@media only screen and (min-width: 768px)': {
			':not(:last-child)': {
				margin: '0 12px 0 0',
			},
			margin: 0,
		},
		'@media only screen and (min-width: 1024px)': {
			':not(:last-child)': {
				margin: '0 90px 0 0',
			},
			margin: 0,
		},
	},
	plusOneOptionWrap: {
		'@media only screen and (min-width: 1024px)': {
			margin: '24px auto 0',
			width: '100%',
		},
		display: 'flex',
		flexDirection: 'column',
		marginTop: 24,
	},
	checkbox: {
		gap: 8,
		fontSize: 14,
		color: titles,
	},
	actions: {
		width: '100%',
	},
	buttonContainer: {
		display: 'inline-flex',
		marginBottom: 48,
		marginTop: 24,
	},
	divider: {
		maxWidth: 1024,
		width: '100%',
		borderTop: `2px solid ${nameCircles}`,
	},
	guestInfo: {
		display: 'flex',
		flexDirection: 'row',
		gap: 24,
		alignItems: 'center',
	},
	bringingGuestsLabel: {
		marginLeft: 6,
	},
	textInput: {
		width: '100%',
		boxSizing: 'border-box',
	},
	textArea: {
		height: 68,
	},
	selectBoxtriggerButton: {
		border: `1px solid ${charmGray}`,
		background: hexToRGBA(borderColor, 0.3),
		borderRadius: 8,
	},
});
